
import { defineComponent } from 'vue';

import Header from '@/components/Header.vue';
import FinishIllustration from '@/components/illustrations/FinishIllustration.vue';
import { useRouter } from 'vue-router';

const DemoFinishedBiometryView = defineComponent({
    components: { FinishIllustration, Header },
    setup() {
        const { replace } = useRouter();
        //demo cannot be used in production
        process.env.NODE_ENV === 'production' ? replace("/login"): ""
        const redirect = () => {
            const url = process.env.VUE_APP_EXAM_FRONT_URL;
            window.open(url);
        };

        return { redirect };
    }
});

export default DemoFinishedBiometryView;
