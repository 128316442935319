import { ref } from 'vue';

export default function useCountdownTimer(milliseconds: number, callback: { (): void; (): void; }, step: number = 100) {

    const countdown = ref(0);
    let timer: number | undefined = undefined;
    let running: boolean = false;

    const start = () => {
        reset();
        running = true;
        countdown.value = milliseconds;
        timer = setInterval(() => {
            if (countdown.value > 0) {
                countdown.value -= step;
                return;
            }
            reset();
            running = false;
            if (callback && typeof callback === 'function') {
                callback();
            }
        }, step);
    };

    const reset = () => {
        clearInterval(timer);
    };

    const isRunning = () => running;

    return { start, reset, isRunning };
}