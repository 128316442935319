import { inject, watch, Ref } from 'vue';

export default function useSignalReceiver(signalName: string) {

    const receive = (callback: (arg: string) => void) => {
        const signal = inject<Ref<boolean>>(signalName);
        if (signal == undefined) return;

        const message = inject<Ref<string>>(`${signalName}__MESSAGE`);
        watch(() => signal!.value, (): void => {
            if (callback && typeof callback === 'function') {
                callback(message!.value);
            }
        });
    };

    return { receive };
}