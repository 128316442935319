import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "fixed h-14 top-0 right-0 left-0 bg-white shadow-2 flex items-center z-40" }
const _hoisted_2 = {
  key: 0,
  class: "flex cursor-pointer",
  title: "Listagem de exames"
}
const _hoisted_3 = {
  key: 1,
  class: "text-2xl font-semibold ml-2 border-l pl-8"
}
const _hoisted_4 = { class: "flex items-center justify-end flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.activeMenu)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode(_component_Icon, {
            icon: "menu-burger",
            size: "45px",
            class: "ml-4 mt-  w-full",
            onClick: _ctx.toggleMenu
          }, null, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    _createVNode("div", {
      class: "logo mx-7 cursor-pointer",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('logoClick'))),
      alt: "logo"
    }),
    (_ctx.vm.pageTitle != '')
      ? (_openBlock(), _createBlock("div", _hoisted_3, _toDisplayString(_ctx.vm.pageTitle), 1))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}