
import { defineComponent, onMounted, ref, watch } from 'vue';
import vm from "@/viewModels/MainViewModel";
import Icon from './Icon.vue';
import route from '@/router/index'

const Header = defineComponent({
    components: { Icon },
    setup() {
        const pathMenu = "/exames";
        const activeMenu = ref<boolean>(false);
        const toggleMenu = () => {
            const sidebar = document.getElementById('menu')!;
            const content = document.getElementById('content')!;
            if (sidebar.classList.contains('hidden')) {
                vm.hiddenMenu = false;
                sidebar.classList.remove('hidden');
                content.classList.replace('col-span-12', 'col-span-9');
            } else {
                vm.hiddenMenu = true;
                sidebar.classList.add('hidden');
                content.classList.replace('col-span-9', 'col-span-12');
            }
        };

        onMounted(()=> {activeMenu.value = route.currentRoute.value.fullPath == pathMenu })

        watch(() => route.currentRoute.value.fullPath, (newValue) => {
            activeMenu.value = newValue == pathMenu;
        })

        return { vm, toggleMenu, activeMenu };
    },
});

export default Header;
