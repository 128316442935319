import { GetGestorSession, PostGestor } from "./BaseService";
import $httpGestor from '../plugins/httpGestor';
import { deviceId } from "@/utils/stringFunctions";
import { CreateExamDTO } from "@/dtos/CreateExamDTO";

interface RequestLoginDTO {
    deviceId: string | null;
    username: string;
    password: string;
}

export default {
    createExamGestor: async (createExam: CreateExamDTO) => {
        const exam = await PostGestor<CreateExamDTO, any>('api/app/exam/exams/create', { ...createExam })[0];
        return exam;
    }
};

