
import { defineComponent, onMounted, reactive, Ref, watch, ref, onUnmounted } from 'vue';
import vm, { FederativeUnit } from '@/viewModels/MainViewModel';
import Icon from './Icon.vue';
import useCountdownTimer from '@/hooks/countdownTimer';
import useSignalReceiver from '@/hooks/signalReceiver';

interface MonitorLogin {
    loading: boolean,
    validateOnInput: boolean,
    user: string,
    password: string,
    federativeUnit: string,
    federativeUnits: FederativeUnit[],
    showDropdown: boolean,
    federativeUnitName: string, 
    menuListWidth: number
}

const SelectStates = defineComponent({
    emits: ['changeUf', 'stateClicked'],
    components: {
        Icon
    },
    props: {
        showDropdown: {
            type: Boolean
        },
        selectedState: {
            type: String,
            default: ''
        },
        showNoSelectionOption: {
            type: Boolean,
            default: false
        },
        showOnlyActive: {
            type: Boolean,
            default: false
        }, 
        parentScrolledEventId: {
            type: String, 
            default: undefined
        }, 
        heightClass: {
            type: String, 
            default: 'h-8'
        }, 
        noSelectionOptionText: {
            type: String,
            default: 'Todos os estados'
        },
    },
    setup(props, { emit }) {
        const state = reactive<MonitorLogin>({
            loading: false,
            validateOnInput: false,
            user: '',
            password: '',
            federativeUnit: props.selectedState,
            federativeUnits: [],
            showDropdown: props.showDropdown,
            federativeUnitName: vm.federativeUnits
                .filter(x => x.uf === props.selectedState)[0]?.name, 
            menuListWidth: 170 // minheight
        });

        state.federativeUnits = vm.federativeUnits
            .filter(x => x.isActive || !props.showOnlyActive);

        const countdown = useCountdownTimer(1000, () => {
            toggleEffect(false);
        });

        var stateSelectSignal = useSignalReceiver("StateSelectSignal");
        stateSelectSignal.receive(() => {
            if (countdown.isRunning())
                return;
            toggleEffect(true);
            countdown.start();
        });

        const applyEffect = ref(false);
        const toggleEffect = (on: boolean) => applyEffect.value = on;

        watch(() => state.federativeUnit, () => {
            state.showDropdown = false;
        });

        const changeUf = (uf: string, name: string) => {
            state.federativeUnit = uf;
            state.federativeUnitName = name;
            vm.federativeUnitSelected = uf;
            emit('changeUf', uf, name);
        }

        const stateClicked = () => {
            state.showDropdown = !state.showDropdown;
            emit('stateClicked');
        }

        const selectStateClicked = () => {
            updateMenuListDimensions();
            state.showDropdown = !state.showDropdown;
        }

        const showAllStatesClicked = () => {
            state.federativeUnit = '';
            state.showDropdown = !state.showDropdown;
            emit('changeUf', '', '')
        }

        const divRef: Ref<any> = ref(null);
        const b1Ref: Ref<any> = ref(null);
        const updateMenuListDimensions = () => {
            const w0 = state.menuListWidth;
            const w1 = divRef.value?.getBoundingClientRect()?.width ?? 0;
            const w2 = b1Ref.value?.getBoundingClientRect()?.width ?? 0;
            const largest = [w0, w1, w2]
                .reduce((accumulated, current) => Math.max(accumulated, current));
            state.menuListWidth = largest;
        };

        onMounted(() => {
            updateMenuListDimensions();
        });
        
        let scrollReceiver = undefined;
        if(props.parentScrolledEventId){
            scrollReceiver = useSignalReceiver(props.parentScrolledEventId);
            scrollReceiver.receive(() => {
                // fechar menu dropdown quando for disparado evento de scroll no componente pai
                state.showDropdown = false;
            });
        }

        onUnmounted(() => {
            countdown.reset();
        });
        
        return { state, changeUf, stateClicked, showAllStatesClicked, divRef, selectStateClicked, b1Ref, applyEffect };
    }
});

export default SelectStates;
